import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterSvg } from '@assets';
import { IconButtonTooltip } from '@components';

interface IFilterButton {
  disabled: boolean;
}

export const FilterButton: FC<IFilterButton> = ({ disabled }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <IconButtonTooltip
      disableInteractive
      title={t('common.grid.button.tooltip.search.orbit')}
    >
      <Box component="span" sx={{ display: 'block' }}>
        <IconButton
          disableRipple
          sx={{
            width: '2.5rem',
            marginLeft: '0',
            height: '2.5rem',
            background: theme.palette.success.main,
            '&:hover': {
              boxShadow: theme.customShadows.iconButton,
            },
            '&.Mui-disabled': {
              background: theme.palette.success.main,
            },
          }}
          disabled={disabled}
          edge="start"
          type="submit"
        >
          <FilterSvg />
        </IconButton>
      </Box>
    </IconButtonTooltip>
  );
};
