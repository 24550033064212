import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import ResetFiltersSvg from '@assets/ResetFilters';
import { IconButtonTooltip } from '@components';

import { useAppSelector } from '@hooks';

interface IResetFiltersButton {
  loading: boolean;
  clearFilters: () => void;
}

export const ResetFiltersButton: FC<IResetFiltersButton> = ({
  loading,
  clearFilters,
}) => {
  const mode = useAppSelector((state) => state.appConfig.config.mode);
  const theme = useTheme();
  const isDarkMode = mode === 'dark';
  const { t } = useTranslation();

  return (
    <IconButtonTooltip
      disableInteractive
      title={t('common.grid.button.tooltip.reset.orbit')}
    >
      <IconButton
        disableRipple
        sx={{
          width: '2.5rem',
          height: '2.5rem',
          background: theme.palette.primary.contrastBackground,
          '&:hover': {
            boxShadow: theme.customShadows.iconButton,
            background: isDarkMode ? 'transparent' : theme.palette.common.white,
            '& svg': {
              '& circle': { fill: theme.palette.error.darker },
            },
          },
        }}
        color="inherit"
        component="span"
        disabled={loading}
        edge="start"
        onClick={clearFilters}
      >
        <ResetFiltersSvg />
      </IconButton>
    </IconButtonTooltip>
  );
};
