import { useTheme } from '@mui/material/styles';
export const useModalStyles = () => {
  const theme = useTheme();
  const maintenanceWrapper = {
    gap: 2,
    minHeight: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    background: theme.palette.grey.A900 ?? 'transparent',
    '& svg': {
      color: theme.palette.primary.dark,
    },
  };
  const maintenanceTitle = {
    fontWeight: 600,
    paddingTop: '2rem',
    fontSize: '2.875rem',
  };
  const maintenanceLabel = {
    width: '80%',
    paddingTop: '1rem',
    textAlign: 'center',
  };

  return { maintenanceTitle, maintenanceLabel, maintenanceWrapper };
};
