import { Collapse, type CollapseProps } from '@mui/material';
import { type FC, PropsWithChildren } from 'react';

interface IHorizontalExpansionTransition extends CollapseProps {
  isOpen: boolean;
}
export const HorizontalExpansionTransition: FC<
  PropsWithChildren<IHorizontalExpansionTransition>
> = ({ isOpen, children, ...props }) => (
  <Collapse in={isOpen} orientation={props.orientation} {...props}>
    {children}
  </Collapse>
);
