import { Switch as MUISwitch, type SwitchProps, useTheme } from '@mui/material';
import { type FC } from 'react';

export const Switch: FC<SwitchProps> = ({ size, ...props }) => {
  const theme = useTheme();

  return (
    <MUISwitch
      size={size}
      {...props}
      sx={{
        '& .MuiSwitch-track': {
          backgroundColor: theme.palette.grey[400],
        },
        '& .Mui-checked+.MuiSwitch-track': {
          backgroundColor: theme.palette.success.main,
          '&:after': {
            top: '50%',
            content: '""',
            width: '12px',
            height: '10px',
            display: 'block',
            position: 'absolute',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            transform: 'translate(-50%, -50%)',
            left: size === 'ios' ? '25%' : '30%',
            backgroundImage:
              'url("data:image/svg+xml;utf8,<svg xmlns=%22http://www.w3.org/2000/svg%22 width=%2212%22 height=%2210%22 fill=%22none%22 viewBox=%220 0 12 10%22><path fill=%22%23fff%22 d=%22M11.358.688h-.936a.43.43 0 0 0-.337.163L4.564 7.846 1.916 4.491a.43.43 0 0 0-.336-.163H.643c-.09 0-.139.103-.084.172l3.668 4.648a.43.43 0 0 0 .674 0l6.541-8.29a.106.106 0 0 0-.084-.17%22/></svg>")',
          },
        },
      }}
    />
  );
};
