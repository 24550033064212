import {
  InputProps,
  type StackProps,
  type TextFieldProps,
} from '@mui/material';

import { type Dayjs } from 'dayjs';

import { type Option } from '@shared/form/types';

type FilterValue =
  | null
  | Dayjs
  | number
  | string
  | Option
  | boolean
  | number[]
  | Option[]
  | string[];

export interface IFiltersConfig {
  label: string;
  order: number;
  enabled: boolean;
  fieldName: string;
  disabled?: boolean;
  limitTags?: number;
  multiple?: boolean;
  options?: Option[];
  defaultValue?: string;
  isNumberInput?: boolean;
  operator: OPERATOR_TYPE;
  inputProps?: InputProps;
  formElement: FORM_ELEMENT;
  sx?: TextFieldProps['sx'];
  containerProps?: StackProps;
  isFloatNumberInput?: boolean;
  withVirtualization?: boolean;
  sxControl?: TextFieldProps['sx'];
  possibleOperators: OPERATOR_TYPE[];
}

export enum FORM_ELEMENT {
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  DATE_RANGE_END = 'DATE_RANGE_END',
  DATE_RANGE_START = 'DATE_RANGE_START',
  DATE_TIME = 'DATE_TIME',
  INPUT = 'INPUT',
  SELECT = 'SELECT',
}

export enum OPERATOR_TYPE {
  EQUAL = 'EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  IN = 'IN',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  LIKE = 'LIKE',
  NOT_EQUAL = 'NOT_EQUAL',
  NOT_IN = 'NOT_IN',
  NOT_LIKE = 'NOT_LIKE',
}

export interface ISearchParams {
  page: number;
  size: number;
  sorts: ISortParams[];
  filters: IFiltersParams[];
  includeExternalBonuses?: boolean;
}

export interface IInitialFilter {
  operator?: string;
  inversion: boolean;
  value?: FilterValue;
}

export interface ISortParams {
  field: string;
  order: OPERATOR_SORT;
}
export interface IFiltersParams {
  field: string;
  value: FilterValue;
  inversion?: boolean;
  type: OPERATOR_TYPE;
}

export interface IPaginationConfig {
  pageSize: number;
  pageIndex: number;
  totalCount: number;
}

export enum OPERATOR_SORT {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IFilterChip {
  field: string;
  operator?: string;
  inversion: boolean;
  value?: FilterValue;
  fieldLabel?: string;
  formElement?: string;
  valueLabel?: number | string | string[];
}

export enum CHIPS_ALLOWED_MODULE {
  BONUSES = 'playerBonuses',
  CODE = 'playerCode',
  OFFERS = 'playerOffers',
}
