import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  type SxProps,
  Typography,
} from '@mui/material';
import { type Theme } from '@mui/material/styles';
import { type FC, PropsWithChildren, type ReactNode, useCallback } from 'react';
import { MdClose } from 'react-icons/md';

import { PopupTransition } from '@components';

interface IModal {
  title: string;
  sx?: SxProps<Theme>;
  isModalOpen: boolean;
  disableBackDrop?: boolean;
  titleChildren?: ReactNode;
  handleCloseModal: () => void;
  maxWidth?: DialogProps['maxWidth'];
}

export const Modal: FC<PropsWithChildren<IModal>> = ({
  title,
  sx = {},
  children,
  isModalOpen,
  maxWidth = 'sm',
  handleCloseModal,
  titleChildren = null,
  disableBackDrop = true,
}) => {
  const closeHandler = useCallback(
    (_: unknown, reason: string) => {
      if (!(disableBackDrop && reason === 'backdropClick')) {
        handleCloseModal();
      }
    },
    [disableBackDrop, handleCloseModal]
  );

  return (
    <Dialog
      maxWidth={maxWidth}
      onClose={closeHandler}
      open={isModalOpen}
      scroll="paper"
      slots={{ transition: PopupTransition }}
      sx={sx}
    >
      <DialogTitle>
        <Stack
          sx={{
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3">{title}</Typography>
          <IconButton
            color="primary"
            onClick={handleCloseModal}
            size="large"
            sx={{ marginLeft: '1rem', marginRight: '-1rem' }}
          >
            <MdClose />
          </IconButton>
        </Stack>
        {titleChildren}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
