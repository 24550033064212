import { Grid2, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GiAutoRepair } from 'react-icons/gi';

import { useModalStyles } from '@components/common/Maintenance/useModalStyles';
interface IMaintenanceComponents {
  message: string;
}
export const MaintenanceComponent: FC<IMaintenanceComponents> = ({
  message,
}) => {
  const { t } = useTranslation();
  const { maintenanceTitle, maintenanceLabel, maintenanceWrapper } =
    useModalStyles();

  return (
    <Grid2 container sx={maintenanceWrapper}>
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <GiAutoRepair size="9rem" />
        <Typography sx={maintenanceTitle}>
          {t('common.maintenance.title')}
        </Typography>
        <Typography sx={maintenanceLabel} variant="h2">
          {message ?? ''}
        </Typography>
      </Stack>
    </Grid2>
  );
};
