import { Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { type TooltipProps } from '@mui/material/Tooltip/Tooltip';
import { type FC, PropsWithChildren } from 'react';

type IconButtonTooltipProps = {
  title: string;
} & TooltipProps;

export const IconButtonTooltip: FC<
  PropsWithChildren<IconButtonTooltipProps>
> = ({ title, children, disableInteractive = false, ...props }) => {
  const theme = useTheme();

  return (
    <Tooltip
      disableInteractive={disableInteractive}
      title={title}
      {...props}
      slotProps={{
        tooltip: {
          sx: {
            fontSize: '0.625rem',
            backgroundColor: 'transparent',
            marginTop: '-0.125rem !important',
            color: theme.palette.text.primary,
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
