import { IconButton, IconButtonProps, useTheme } from '@mui/material';
import {
  type FC,
  type MouseEvent,
  PropsWithChildren,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { MdCopyAll } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useCopyToClipboard } from 'react-use';

import { useAppSelector } from '@hooks';

interface ICopyButtonProps {
  value: string;
  fieldName: string;
  withIcon?: boolean;
  sx?: IconButtonProps['sx'];
}

export const CopyButton: FC<PropsWithChildren<ICopyButtonProps>> = ({
  value,
  sx = {},
  fieldName,
  withIcon = true,
  children = undefined,
}) => {
  const [, copyTextToClipboard] = useCopyToClipboard();
  const theme = useTheme();
  const { t } = useTranslation();

  const isPlatformTheme =
    useAppSelector((state) => state.appConfig.config.presetColor) ===
    'Platform';

  const handleCopy = useCallback(
    (
      e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
      { value, fieldName }: ICopyButtonProps
    ) => {
      e.stopPropagation();
      copyTextToClipboard(value ?? '');
      toast.success(t('common.toast.copied', { fieldName }));
    },
    [copyTextToClipboard, t]
  );

  return (
    <IconButton
      onClick={(e) => {
        handleCopy(e, {
          value,
          fieldName,
        });
      }}
      sx={{
        padding: '0',
        width: '1.375rem',
        height: '1.375rem',
        color: isPlatformTheme
          ? theme.palette.primary.darker
          : theme.palette.grey[500],
        ...sx,
      }}
    >
      {children}
      {withIcon ? <MdCopyAll /> : null}
    </IconButton>
  );
};
