import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { TreeItem, type TreeItemProps } from '@mui/x-tree-view';
import { forwardRef, type MouseEvent, type Ref, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCopyAll } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useCopyToClipboard } from 'react-use';

import { type ICustomFrontIcon } from '@components';
type CustomTreeItemProps = {
  labelText: string;
  withCopy?: boolean;
  customFrontIcon: null | ICustomFrontIcon;
} & TreeItemProps;

export const CustomTreeItem = forwardRef(function StyledTreeItem(
  props: CustomTreeItemProps,
  ref: Ref<HTMLLIElement>
) {
  const { t } = useTranslation();

  const { labelText, customFrontIcon, withCopy = false, ...other } = props;

  const [, copyTextToClipboard] = useCopyToClipboard();

  const handleCopy = useCallback(
    (
      e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
      label: string
    ) => {
      e.stopPropagation();
      const formattedLabel = label?.split('(')[0].trim();
      copyTextToClipboard(formattedLabel ?? '');
      toast.success(
        t('common.toast.copied', { fieldName: t('common.campaign.name') })
      );
    },
    [copyTextToClipboard, t]
  );

  return (
    <TreeItem
      ref={ref}
      {...other}
      label={
        <>
          {customFrontIcon && (
            <Tooltip title={customFrontIcon.tooltip ?? ''}>
              <Stack
                sx={{
                  marginRight: '0.5rem',
                }}
              >
                {customFrontIcon.icon}
              </Stack>
            </Tooltip>
          )}
          <Typography>{labelText}</Typography>

          {withCopy && (
            <IconButton
              onClick={(e) => {
                handleCopy(e, labelText);
              }}
              sx={{
                opacity: '0',
                padding: '0',
                transition: '.4s',
                width: '1.375rem',
                height: '1.375rem',
                marginLeft: '0.5rem',
              }}
              color="secondary"
            >
              <MdCopyAll />
            </IconButton>
          )}
        </>
      }
    />
  );
});
