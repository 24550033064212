import { IconButton, Link, Tooltip } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { FC, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GoLinkExternal } from 'react-icons/go';

import { ROUTES } from '@constants';
import { POST_MESSAGE_EVENT_TYPE } from '@modules/types';
import replaceURLParams from '@utils/replaceURLParams';
import { sendPostMessage } from '@utils/sendPostMessage';

interface IBonusTemplateDetailsButton {
  rowName: string;
  rowIndex: number;
  disabled: boolean;
}

interface IBonusTemplate {
  id: string;
  bonusType: string;
  bonusName: null | {
    value: string;
    label: string;
  };
}

const { OPEN_FREE_SPIN_DETAILS, OPEN_BONUS_TEMPLATE_DETAILS } =
  POST_MESSAGE_EVENT_TYPE;

export const BonusTemplateDetailsButton: FC<IBonusTemplateDetailsButton> = ({
  rowName,
  rowIndex,
  disabled,
}) => {
  const { t } = useTranslation();
  const isIframe = window.self !== window.top;
  const { watch } = useFormContext();

  const bonusTemplate = watch(rowName)?.[rowIndex] as
    | undefined
    | IBonusTemplate;

  const isFreeSpinType = bonusTemplate?.bonusType === 'FREE_SPIN';

  const openBonusDetails = useCallback(() => {
    const eventType = isFreeSpinType
      ? OPEN_FREE_SPIN_DETAILS
      : OPEN_BONUS_TEMPLATE_DETAILS;

    sendPostMessage(eventType, bonusTemplate?.bonusName?.value);
  }, [bonusTemplate?.bonusName, isFreeSpinType]);

  const isButtonDisabled = useMemo(
    () => disabled || isEmpty(bonusTemplate?.bonusName),
    [disabled, bonusTemplate?.bonusName]
  );

  const isLinkDisabled = useMemo(
    () => disabled || isEmpty(bonusTemplate?.bonusName) || isFreeSpinType,
    [disabled, bonusTemplate?.bonusName, isFreeSpinType]
  );

  const bonusDetailsLink = useMemo(
    () =>
      !isLinkDisabled && bonusTemplate?.bonusName?.value
        ? replaceURLParams(ROUTES.BONUS_MANAGEMENT.EDIT, {
            id: bonusTemplate?.bonusName.value,
          })
        : undefined,
    [bonusTemplate?.bonusName, isLinkDisabled]
  );

  return (
    <Tooltip
      title={t('campaign.scheduled.details.tier.button.redirect.tooltip')}
    >
      {isIframe ? (
        <IconButton
          sx={{
            height: '2.141rem',
            pointerEvents: 'auto',
          }}
          color="primary"
          disabled={isButtonDisabled}
          onClick={openBonusDetails}
          size="medium"
        >
          <GoLinkExternal />
        </IconButton>
      ) : (
        <Link href={bonusDetailsLink} target="_blank">
          <IconButton
            sx={{
              height: '2.141rem',
              pointerEvents: 'auto',
            }}
            color="primary"
            disabled={isLinkDisabled}
            size="medium"
          >
            <GoLinkExternal />
          </IconButton>
        </Link>
      )}
    </Tooltip>
  );
};
